const HeroSectionContent = () => {
  const title = "Two decades of natural hazards research";
  const description =
    "This site compiles key outputs from research conducted by the Bushfire Cooperative Research Centre (2003-2014) and the Bushfire and Natural Hazards Cooperative Research Centre (2013-2021).";
  return (
    <>
      <div className="flex items-center flex-col gap-1 text-base font-sans leading-5 p-10 md:pt-8 md:pb-20  text-black bg-yellow">
        <h1 style={{font: "normal normal normal 52px/60px Titillium Web" ,letterSpacing: "0px",color: "#000000" , opacity: 1}} className="font font-normal text-center  text-5xl text-black sm:leading-relaxed leading-10">
          {title}
        </h1>
        <br/>
        <p style={{font: "normal normal normal 20px/28px Titillium Web" ,letterSpacing: "0px",color: "#000000" , opacity: 1}} className=" font-normal max-w-4xl text-xl	text-center text-black">
          {description}
        </p>
      </div>
    </>
  );
};

export default HeroSectionContent;
