import {FETCH_BLOGS} from '../constants/actionTypes';

const blogsReducer =(state={blogs : null},action) =>{
    switch(action.type){
        case FETCH_BLOGS:
            return action.payload;
        default:
            return state;
    }
}

export default blogsReducer;