import { useEffect } from "react";
import { InstantSearch, SearchBox } from "react-instantsearch-dom";
const algoliasearch = require("algoliasearch/lite");
const StickyNavbar = ({
  bgBackground,
  updatequery,
  placeholder_label,
  searchInput,
  label,
}) => {
  const searchClient = algoliasearch(
    "07TRPO77DC",
    "5cfccb8ce8d3e8f3a1a35d96d942502b"
  );
  useEffect(() => {
    window.onscroll = function () {
      myFunction();
    };

    var navbar = document.getElementById("navbar");
    var sticky = navbar?.offsetTop;
    // console.log(sticky);

    function myFunction() {
      // console.log(window.scrollY);
      if (window.scrollY > 120) {
        navbar?.classList.add("sticky");
      } else {
        navbar?.classList.remove("sticky");
      }
    }
    return () => {
      myFunction();
      navbar?.classList.remove("sticky");
    };
  }, []);

  return (
    <div className={`${bgBackground}`} id="navbar">
      {searchInput ? (
        <InstantSearch
          searchClient={searchClient}
          id="search_form"
          indexName=""
        >
          <div className="flex items-center justify-center flex-col">
            <SearchBox
              onChange={updatequery}
              searchAsYouType={false}
              onKeyDown={updatequery}
              translations={{
                placeholder:
                  placeholder_label !== "" ? placeholder_label : "Site search",
              }}
              style={{ color: "red", fontWeight: "900" }}
            />
          </div>
        </InstantSearch>
      ) : (
        <h1
        
          style={{
            textAlign: "center",
            font: "normal normal normal 52px/60px Titillium Web",
            letterSpacing: "0px",

          }}
        >
          {label}
        </h1>
      )}
    </div>
  );
};

export default StickyNavbar;
