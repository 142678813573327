import BushFire from "../images/bushfire.png";
import BushFireCRC from "../images/bushFireCrc.png";
import HazardResearch from "../images/hazard_research.png";
const CompanySection = () => {
  return (
    <div className="flex items-center flex-col  text-base font-sans p-10 md:p-16  ">
      <div className="flex  sm:gap-36 flex-col sm:flex-row justify-center items-center ">
        <img src={BushFire} alt="Not Found" className="object-contain w-52 " />
        <img
          src={BushFireCRC}
          alt="Not Found"
          className="object-contain w-60 "
        />
      </div>
     <div   className=" tracking-normal max-w-3xl text-center text-base leading-6 mt-4 "
        style={{
          font: "normal normal normal 16px/20px Titillium Web",
          letterSpacing: "0px",
          color: "#707070",
          opacity: 1,
        }}>
          <p className="m-1 p-1" style={{font: "normal normal normal 16px/20px Titillium Web",}}>
        Over 20 years, the two CRCs drew together Australian fire, land and
        emergency services with leading experts across a range of scientific
        fields. They explored in detail the causes, consequences and mitigation
        of natural hazards, with a focus on delivering research that is useful
        and industry driven.
      </p>
       <p className="m-1 p-1"><a href="/organisations" className="underline">Browse a full list of all Organisations that participated in the CRCs</a></p>
        </div>
    </div>
  );
};

export default CompanySection;
