// src/context/PageTitleContext.js
import React, { createContext, useContext, useState } from "react";
import { Helmet } from "react-helmet";

const PageTitleContext = createContext();

export const PageTitleProvider = ({ children }) => {
  const [title, setTitle] = useState("CRC-Collection");

  const updateTitle = (newTitle) => {
      setTitle(newTitle ? `CRC-Collection:${newTitle}` : "CRC-Collection");
  };

  return (
    <PageTitleContext.Provider value={{ setTitle: updateTitle }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </PageTitleContext.Provider>
  );
};

export const usePageTitle = () => useContext(PageTitleContext);
