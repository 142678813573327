import {FETCH_FIRE_AUSTRALIA} from '../constants/actionTypes';

const fireaustraliaReducer =(state={fireaustralia : null},action) =>{
    switch(action.type){
        case FETCH_FIRE_AUSTRALIA:
            return action.payload;
        default:
            return state;
    }
}

export default fireaustraliaReducer;