import HazardResearch from "../images/austrailia-logo.png";

const FooterSection = () => {
  return (
    <div
      style={{ boxShadow: "0px -3px 6px #00000029" }}
      className="mt-12 flex flex-col lg:flex-row justify-evenly gap-4 sm:gap-10 bg-sliver_black w-full items-center pt-3 pb-2 sm:pt-0 sm:pb-0 h-60 lg:h-36"
    >
      <div className="flex items-center gap-2">
        
          <img src={HazardResearch} height={"120px"} width={"120px"} />
          <p className="text-white font-bold">Australian Government</p>
       
      </div>

      <div className="flex gap-20 items-center"> 
        <div className="flex gap-4 justify-center items-center">  
           <span className="text-white">|</span>
           <a href="https://www.naturalhazards.com.au/contact-us" className="privacy-contact-us">Contact us</a>
          </div>
        <div className="flex gap-4 justify-center items-center"> 
        <span className="text-white">|</span> {/* Separator between "Contact us" and "Privacy" */}
        <a href="https://www.naturalhazards.com.au/privacy-policy-statement" className="privacy-contact-us">Privacy</a>
        </div>
      </div>

      <div>
        <p className="copy-write">©{new Date().getFullYear()} Natural Hazards Research Australia</p>
      </div>
    </div>
  );
};

export default FooterSection;
