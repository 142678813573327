import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import decode from "decode-html";
import { getProjectNodeById } from "../../actions/common";

const Node = () => {
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const nid = urlParams.get("nid");

  useEffect(() => {
    dispatch(getProjectNodeById(nid));
  }, [dispatch, nid]);

  const TeamDetails = (props) => {
    const roles = props.roles || {};
    
    const fields = [
      { title: "Leaders", key: "NameLeader" },
      { title: "Researchers", key: "NameResearcher" },
      { title: "Users", key: "NameUser" },
      { title: "Students", key: "NameStudent" },
      { title: "Associates", key: "NameAssoc" },
      { title: "Team", key: "NameTeam" },
    ];
    
    return (
      <>
        {fields.map(({ title, key }) => (
          <div key={key} className="flex gap-1 flex-col mt-2">
             {key==="NameLeader" ? null : <hr className="-z-10" />}
            <h2 className="font-semibold text-sm">{title}</h2>
            {roles[key] && (
              <div
                className="font-normal text-sm"
                dangerouslySetInnerHTML={{ __html: Array.isArray(roles[key]) ? roles[key].map(item => `<div>${item}</div>`).join('') : `<div>${roles[key]}</div>` }}
              ></div>
            )}
          </div>
        ))}
      </>
    );
  };

  const DisplayNode = () => {
    const node = useSelector((state) => state.projects);
    if (!node) return null;

    return (
      <div className="mx-auto">
        <div className="w-full">
          <h4 className="text-black text-2xl font-bold mb-3">
            <div dangerouslySetInnerHTML={{ __html: `${node.Title}` }}></div>
          </h4>
        </div>
        <div className="flex flex-col md:flex-row gap-4 max-w-[752px]">
          <div className="w-full">
            <img src={node.Image} className="object-contain mb-3" alt="Project" />
            {node.Body && (
              <div className="text-lg text-black" dangerouslySetInnerHTML={{ __html: decode(node.Body) }}></div>
            )}
          </div>
          <div className="md:w-1/4 w-full flex flex-col gap-2 team_details">
            {node.Roles && <TeamDetails roles={node.Roles} />}
            <div className="flex gap-1 flex-col">
              <hr className="-z-10" />
               <h2 className="font-bold text-base">Type</h2>
              <p className="font-normal text-sm">{node.ProjType}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="flex justify-between gap-10 pt-10 pb-10 flex-col md:flex-row min-h-[700px] max-w-[752px] mx-auto">
        <DisplayNode />
      </div>
    </div>
  );
};

export default Node;
