import {FETCH_RESEARCHBRIEFINGS} from '../constants/actionTypes';

const researchbriefingsReducer =(state={researchbriefings : null},action) =>{
    switch(action.type){
        case FETCH_RESEARCHBRIEFINGS:
            return action.payload;
        default:
            return state;
    }
}

export default researchbriefingsReducer;