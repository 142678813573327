// import { FETCH_PUBLICATIONS, FETCH_BLOGS, FETCH_RESOURCES, FETCH_HAZARDNOTES, FETCH_PROJECTS, FETCH_FIRE_AUSTRALIA } from '../constants/actionTypes';
// import { FETCH_BIBLIOS,FETCH_PUBLICATIONS, FETCH_BLOGS, FETCH_RESOURCES, FETCH_HAZARDNOTES, FETCH_PROJECTS, FETCH_FIRE_AUSTRALIA } from '../constants/actionTypes';
import { FETCH_PUBLICATIONS, FETCH_BLOGS, FETCH_RESOURCES, FETCH_RESEARCHBRIEFINGS, FETCH_PROJECTS, FETCH_FIRE_AUSTRALIA } from '../constants/actionTypes';

// import BiblioJSON from '../data/search/jsonbycontenttype/biblio.json'
import PublicationJSON from '../data/search/jsonbycontenttype/publications_latest_one.json'
// import BlogJSON from '../data/search/jsonbycontenttype/blogs2.json'
// import BlogJSON from '../data/search/jsonbycontenttype/blogs_new.json'
import BlogJSON from '../data/search/jsonbycontenttype/blogs_new_2024.json'
import ResourceJSON from '../data/search/jsonbycontenttype/resources2.json'
// import ProjectJSON from '../data/search/jsonbycontenttype/projects2.json'
import ProjectJSON from '../data/search/jsonbycontenttype/projectsx.json'
// import HazardNoteJSON from '../data/search/jsonbycontenttype/hazardnotes2.json'
import ResearchBriefingsJSON from '../data/search/jsonbycontenttype/researchbriefings.json'
// import FireAustraliaJSON from '../data/search/jsonbycontenttype/fireaustralia.json'
import FireAustraliaJSON from '../data/search/jsonbycontenttype/fireaustralia2.json'


// export const getBiblios = () => async (dispatch) => {

//     try {
//         const { data } = { data: PublicationJSON }
//         dispatch({ type: FETCH_BIBLIOS, payload: data });
//     } catch (error) {
//         console.log(error.message)
//     }
// }

// export const getBibliosByYear = (year) => async (dispatch) => {
//     try {
//         const { data } = { data: BiblioJSON }
//         const bibByYear = await data.filter((item) => item.Year == year)
//         dispatch({ type: FETCH_BIBLIOS, payload: bibByYear });
//     } catch (error) {
//         console.log(error.message)
//     }
// }

// export const getBiblioNodeById = (Id) => async (dispatch) => {
//     try {
//         const { data } = { data: BiblioJSON }
//         const bibNode = await data.filter((item) => item.NID == Id)
//         dispatch({ type: FETCH_BIBLIOS, payload: bibNode[0] });
//     } catch (error) {
//         console.log(error.message)
//     }
// }

export const getPublications = () => async (dispatch) => {

    try {
        const { data } = { data: PublicationJSON }
        dispatch({ type: FETCH_PUBLICATIONS, payload: data });
    } catch (error) {
        console.log(error.message)
    }
}

export const getPublicationsByYear = (year) => async (dispatch) => {
    try {
        const { data } = { data: PublicationJSON }
        const bibByYear = await data.filter((item) => item.Year == year)
        dispatch({ type: FETCH_PUBLICATIONS, payload: bibByYear });
    } catch (error) {
        console.log(error.message)
    }
}

export const getPublicationNodeById = (Id) => async (dispatch) => {
    try {
        const { data } = { data: PublicationJSON }
        const bibNode = await data.filter((item) => item.NID == Id)
        dispatch({ type: FETCH_PUBLICATIONS, payload: bibNode[0] });
    } catch (error) {
        console.log(error.message)
    }
}

export const getBlogs = () => async (dispatch) => {
    try {
        const { data } = { data: BlogJSON }
        dispatch({ type: FETCH_BLOGS, payload: data });
    } catch (error) {
        console.log(error.message)
    }
}

export const getBlogsByName = (name) => async (dispatch) => {
    try {
        const { data } = { data: BlogJSON }
        const drBlogs = await data.filter((item) => item.Name == name)
        dispatch({ type: FETCH_BLOGS, payload: drBlogs });
    } catch (error) {
        console.log(error.message)
    }
}

export const getBlogNodeById = (Id) => async (dispatch) => {
    try {
        const { data } = { data: BlogJSON }
        const blogNode = await data.filter((item) => item.NID == Id)
        dispatch({ type: FETCH_BLOGS, payload: blogNode[0] });

    } catch (error) {
        console.log(error.message)
    }
}

export const getProjects = () => async (dispatch) => {
    try {
        const { data } = { data: ProjectJSON }
        dispatch({ type: FETCH_PROJECTS, payload: data });
    } catch (error) {
        console.log(error.message)
    }
}

export const getProjectsByType = (type) => async (dispatch) => {
    try {
        const { data } = { data: ProjectJSON }
        const projByType = await data.filter((item) => item.ProjType == type)
        dispatch({ type: FETCH_PROJECTS, payload: projByType });
    } catch (error) {
        console.log(error.message)
    }
}

export const getProjectNodeById = (Id) => async (dispatch) => {
    try {
        const { data } = { data: ProjectJSON }
        const projNode = await data.filter((item) => item.NID == Id)
        dispatch({ type: FETCH_PROJECTS, payload: projNode[0] });
    } catch (error) {
        console.log(error.message)
    }
}

// export const getHazardNotes = () => async (dispatch) => {
//     try {
//         const { data } = { data: HazardNoteJSON }
//         dispatch({ type: FETCH_HAZARDNOTES, payload: data });
//     } catch (error) {
//         console.log(error.message)
//     }
// }

// export const getHazardNotesByYear = (year) => async (dispatch) => {
//     try {
//         const { data } = { data: HazardNoteJSON }
//         const hnByYear = await data.filter((item) => item.Date.split('-')[0] == year)
//         dispatch({ type: FETCH_HAZARDNOTES, payload: hnByYear });
//     } catch (error) {
//         console.log(error.message)
//     }
// }

// export const getHazardNoteNodeById = (Id) => async (dispatch) => {
//     try {
//         const { data } = { data: HazardNoteJSON }
//         const hznNode = await data.filter((item) => item.NID == Id)
//         dispatch({ type: FETCH_HAZARDNOTES, payload: hznNode[0] });
//     } catch (error) {
//         console.log(error.message)
//     }
// }

export const getResearchBriefings = () => async (dispatch) => {
    try {
        const { data } = { data: ResearchBriefingsJSON }
        dispatch({ type: FETCH_RESEARCHBRIEFINGS, payload: data });
    } catch (error) {
        console.log(error.message)
    }
}

export const getResearchBriefingsByYear = (year) => async (dispatch) => {
    try {
        const { data } = { data: ResearchBriefingsJSON }
        const hnByYear = await data.filter((item) => item.Date.split('-')[0] == year)
        dispatch({ type: FETCH_RESEARCHBRIEFINGS, payload: hnByYear });
    } catch (error) {
        console.log(error.message)
    }
}

export const getResearchBriefingsById = (Id) => async (dispatch) => {
    try {
        const { data } = { data: ResearchBriefingsJSON }
        const hznNode = await data.filter((item) => item.NID == Id)
        dispatch({ type: FETCH_RESEARCHBRIEFINGS, payload: hznNode[0] });
    } catch (error) {
        console.log(error.message)
    }
}



export const getFireAustralia = () => async (dispatch) => {
    try {
        const { data } = { data: FireAustraliaJSON }
        dispatch({ type: FETCH_FIRE_AUSTRALIA, payload: data });
    } catch (error) {
        console.log(error.message)
    }
}

export const getFireAustraliaByType = (type) => async (dispatch) => {
    try {
        const { data } = { data: FireAustraliaJSON }
        const faByType = await data.filter((item) => item.Type == type)
        dispatch({ type: FETCH_FIRE_AUSTRALIA, payload: faByType });
    } catch (error) {
        console.log(error.message)
    }
}

export const getFireAustraliaNodeById = (Id) => async (dispatch) => {
    try {
        const { data } = { data: FireAustraliaJSON }
        const faNode = await data.filter((item) => item.NID == Id)
        dispatch({ type: FETCH_FIRE_AUSTRALIA, payload: faNode[0] });
    } catch (error) {
        console.log(error.message)
    }
}

export const getResources = () => async (dispatch) => {
    try {
        const { data } = { data: ResourceJSON }
        dispatch({ type: FETCH_RESOURCES, payload: data });
    } catch (error) {
        console.log(error.message)
    }
}

export const getResourcesByType = (type) => async (dispatch) => {
    try {
        const { data } = { data: ResourceJSON }
        const resByType = await data.filter((item) => item.Type == type)
        dispatch({ type: FETCH_RESOURCES, payload: resByType });
    } catch (error) {
        console.log(error.message)
    }
}

export const getResourceNodeById = (Id) => async (dispatch) => {
    try {
        const { data } = { data: ResourceJSON }
        const resourceNode = await data.filter((item) => item.NID == Id)
        dispatch({ type: FETCH_RESOURCES, payload: resourceNode[0] });
    } catch (error) {
        console.log(error.message)
    }
}
