import { Children } from "react";
import { BreadCrumbContainer } from "./BreadCrumbContext";
import { useState } from "react";

const BreadCrumbProvider = ({ children }) => {
  const [breadCrumbLinks, setBreadCrumbLinks] = useState([
    { name: "Home", link: "/" },
  ]);
  const [breadCrumbColor, setBreadCrumbColor] = useState("white");

  const data = {
    breadCrumbLinks,
    setBreadCrumbLinks,
  };
  return <BreadCrumbContainer value={data}>{children}</BreadCrumbContainer>;
};

export default BreadCrumbProvider;
