import {FETCH_RESOURCES} from '../constants/actionTypes';

const ressourcesReducer =(state={resources : null},action) =>{
    switch(action.type){
        case FETCH_RESOURCES:
            return action.payload;
        default:
            return state;
    }
}

export default ressourcesReducer;