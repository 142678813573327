const LatestSearch = () => {
  return (
    <div className="relative -mb-12" style={{ background: "#F3F3F3" }}>
      <div className="container p-8 sm:p-20 ">
        <div className="flex gap-10 justify-center flex-col items-center">
          <h1
            className="max-w-2xl font-normal font  text-center text-blue z-10 "
            style={{ fontSize: "32px" }}
          >
            For current and related research, visit Natural Hazards Research
            Australia
          </h1>
          <a
            href="https://naturalhazards.com.au"
            className="flex items-center justify-center border text-center rounded cursor-pointer z-10 bg-green text-md focus:outline-none text-white font-bold pl-4 pr-4  hover:bg-blackish_grey"
            style={{
              font: "normal normal bold 16px/20px Titillium Web",
              letterSpacing: "0px",
              opacity: "1",
              width: "220px",
              height: "45px",
            }}
          >
            Search the latest research
          </a>
        </div>
      </div>
    </div>
  );
};

export default LatestSearch;
