import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBreadCrumb } from "../../context/BreadCrumbContext";
import { useLocation } from "react-router-dom";
import { usePageTitle } from "../../context/PageTitleContext";

const BreadCrumb = () => {
  const {
    breadCrumbLinks,
    setBreadCrumbLinks,
    breadCrumbColor,
    setBreadCrumbColor,
  } = useBreadCrumb();
  const { setTitle } = usePageTitle();

  const [textColor, setTextColor] = useState("text-white");
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    // console.log(location, "lll", location.search.includes("CT"));

    if (location.pathname !== "/results" || location.search.includes("CT")) {
      setTextColor("text-white");
    } else {
      setTextColor("text-black");
    }
  }, []);
  // }, [location.pathname]);

  const onHandle = () => {
    setBreadCrumbLinks([...breadCrumbLinks, { name: "About", link: "/" }]);
  };

  const navigate = useNavigate();

  const handleRoute = (item) => {
    setTitle("");
    navigate(item);
  };

  return (
    <nav className="hidden sm:block">
      <ol className="list-reset flex  last:font-bold " style={{listStyle:"none"}} >
        {breadCrumbLinks?.map((item, index) => {
          return (
            <li
              key={index}
              style={
                index === breadCrumbLinks.length - 1
                  ? { fontWeight: "bold" }
                  : { fontWeight: "normal" }
              }
            >
              <a
                style={{ fontSize: "14px" }}
                onClick={() => handleRoute(item?.link)}
                className={` cursor-pointer  ${textColor}`}
              >
                {item.name}
              </a>
              {index !== breadCrumbLinks.length - 1 ? (
                <span className={`mx-2  dark:text-neutral-300 ${textColor}`}>
                  |
                </span>
              ) : null}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
export default BreadCrumb;
