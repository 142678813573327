import { useEffect, useState } from "react";
import BushFireCRC from "../images/bushFireCrc-removebg-preview.png";
import BushFire from "../images/bushfire_cut_edges.png";
import organizations from "./organisations.json";
import { useBreadCrumb } from "../../context/BreadCrumbContext";

const Organizations = () => {
  const [currentKey, setCurrentKey] = useState(null);
  const [showSubIndex, setShowSubIndex] = useState(false);
  const { breadCrumbLinks, setBreadCrumbLinks } = useBreadCrumb();
useEffect(()=>{
  setBreadCrumbLinks([
    { name: "Home", link: "/" },
    { name: "Organisations", link: "/organisations" },
  ]);
},[])


  const showHideSubIndex = (key,typeOfDate) => {
    setCurrentKey(key+typeOfDate);
    setShowSubIndex((prev) => (currentKey === key ? !prev : true));
  };

  const groupedOrganizations = organizations.reduce((acc, org) => {
    console.log(org)
    const { CRC, Type, Org } = org;
    if (!acc[CRC]) acc[CRC] = {};
     
    if (!acc[CRC][Type]) acc[CRC][Type] = [];

    acc[CRC][Type].push(Org);
    console.log(acc[CRC][Type])
    return acc;
  }, {});

  const RenderComponent = ({ image,typeOfDate,list }) => {
    return (
      <div className="md:w-1/2 w-full flex flex-column gap-3">
        <img src={image} width={"150px"} alt="Organization Logo" className={typeOfDate=="BFCRC" ? "-mt-7" : null} />
        {Object.entries(list).map(([type, orgs], index) => (
          <div key={index} className="">
            <div
              className="biblio_buttons pl-3 pr-3 w-full flex justify-start items-center cursor-pointer border-md" style={{width:"100%", borderRadius: "4px", border:"1px solid #C3C3C3"}}
              onClick={() => showHideSubIndex(index,typeOfDate)}
            >
              <div className="flex gap-2 items-center">
                <div className="text-lg">
                  {showSubIndex && currentKey === index+typeOfDate ? "-" : "+"}
                </div>
                <div className="text-lg">{type}</div>
              </div>
            </div>
            {showSubIndex && currentKey === index+typeOfDate && (
              <div className=" contentBorder p-4 ">
                {orgs.map((org, idx) => (
                  <div key={idx} className="pl-4 font-semibold text-black p-1 ">{org}</div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="container mt-5 min-h-[500px] max-w-[960px]">
     <div className="flex justify-center mt-2 gap-4 flex-col md:flex-row">
        <RenderComponent image={BushFire} typeOfDate={"BNHCRC"} list={groupedOrganizations?.BNHCRC || {}}  />
        <RenderComponent image={BushFireCRC} typeOfDate={"BFCRC"} list={groupedOrganizations?.BFCRC || {}}  />
      </div>
    </div>
  );
};

export default Organizations;
