import React, { useEffect, useState } from "react";
import decode from "decode-html";
import { useSelector, useDispatch } from "react-redux";
import { getPublicationNodeById } from "../../actions/common";
import { useBreadCrumb } from "../../context/BreadCrumbContext";
import copy from "../svg/copy.svg";

const Node = () => {
  const { setBreadCrumbLinks } = useBreadCrumb();
  const dispatch = useDispatch();
  const queryString = new URLSearchParams(window.location.search);
  const nid = queryString.get("nid");
  const year = queryString.get("year");

  const [isCitationShown, setIsCitationShown] = useState(false);
  const [isBibrawShown, setIsBibrawShown] = useState(false);

  useEffect(() => {
    setBreadCrumbLinks([
      { name: "Home", link: "/" },
      { name: "Publications", link: "/publications" },
      { name: `Publications work - ${year}`, link: `/publications/subindex?year=${year}` },
    ]);
    dispatch(getPublicationNodeById(nid));
  }, [dispatch, nid, year, setBreadCrumbLinks]);

  const copyToClipboard = (elementId) => {
    const contentToCopy = document.getElementById(elementId)?.innerText;
    if (contentToCopy) {
      navigator.clipboard.writeText(contentToCopy).then(() => alert("Content copied to clipboard!"));
    }
  };

  const Citation = ({ citation }) => (
    <pre id="citation" className="text-wrap max-w-[558px]">{citation}</pre>
  );

  const Bibraw = ({ bibraw }) => (
    <pre id="bibtex" className="text-wrap max-w-[558px]" dangerouslySetInnerHTML={{ __html: decode(bibraw) }}></pre>
  );

  const Authors = ({ authors }) => <span>{authors}</span>;

  const node = useSelector((state) => state.publications);

  return (
    <div className="container">
      <div className="flex justify-between gap-10 py-4 max-w-[752px] mx-auto flex-col md:flex-row min-h-[700px]">
        {node && (
          <div>
            <h4 className="text-black text-2xl font-bold" dangerouslySetInnerHTML={{ __html: node.Title }}></h4>
            <div className="flex flex-col md:flex-row gap-4 max-w-[752px]">
              <div className="w-full">
                {node.Abstract && (
                  <div className="max-w-[558px] text-lg" dangerouslySetInnerHTML={{ __html: decode(node.Abstract) }} />
                )}

                {/* Citation Section */}
                <div className="mt-4">
                  <div className="max-w-[558px] citation flex flex-col justify-center">
                    <div className="flex justify-between mx-4 items-center">
                      <button onClick={() => setIsCitationShown(!isCitationShown)}>
                        {isCitationShown ? "- Citation" : "+ Citation"}
                      </button>
                      <img src={copy} onClick={() => copyToClipboard("citation")} alt="Copy" className="cursor-pointer" width="50" height="50" />
                    </div>
                  </div>
                  {isCitationShown && (
                    <div className="citation_body pl-9 pt-4 pb-4 pr-9 text-lg">
                      <Citation citation={node.Citation} />
                    </div>
                  )}
                </div>

                {/* BibTeX Section */}
                <div className="mt-4">
                  <div className="max-w-[558px] citation flex flex-col justify-center">
                    <div className="flex justify-between mx-4 items-center">
                      <button onClick={() => setIsBibrawShown(!isBibrawShown)}>
                        {isBibrawShown ? "- BibTeX" : "+ BibTeX"}
                      </button>
                      <img src={copy} onClick={() => copyToClipboard("bibtex")} alt="Copy" className="cursor-pointer" width="50" height="50" />
                    </div>
                  </div>
                  {isBibrawShown && node.BibTeX && (
                    <div className="citation_body pl-9 pt-4 pb-4 pr-9 text-lg">
                      <Bibraw bibraw={node.BibTeX} />
                    </div>
                  )}
                </div>
              </div>

              {/* Sidebar Details */}
              <div className="md:w-1/4 w-full flex flex-col gap-2 publication_sidebar mt-4">
                {node.Authors && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">Authors</h2>
                    <p className="font-normal text-sm"><Authors authors={node.Authors} /></p>
                  </div>
                )}
                {node.Type && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">Type</h2>
                    <p className="font-normal text-sm">{node.Type}</p>
                  </div>
                )}
                {node.Year && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">Date</h2>
                    <p className="font-normal text-sm">{node.Year}</p>
                  </div>
                )}
                {node.DOI && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">DOI</h2>
                    <p className="font-normal text-sm"><a href={node.DOI} target="_blank" rel="noopener noreferrer">{node.DOI}</a></p>
                  </div>
                )}
                {node.URL && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">URL</h2>
                    <p className="font-normal text-sm"><a href={node.URL} target="_blank" rel="noopener noreferrer">{node.URL}</a></p>
                  </div>
                )}
                {node.Pagination && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">Pagination</h2>
                    <p className="font-normal text-sm">{node.Pagination}</p>
                  </div>
                )}
                {node.Issue && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">Issue</h2>
                    <p className="font-normal text-sm">{node.Issue}</p>
                  </div>
                )}
                {node.Refereed && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">Refereed</h2>
                    <p className="font-normal text-sm">{node.Refereed}</p>
                  </div>
                )}
{/*                 {node.UID && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">UID</h2>
                    <p className="font-normal text-sm">{node.UID}</p>
                  </div>
                )} */}
                {node.Published && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">Published</h2>
                    <p className="font-normal text-sm">{node.Published}</p>
                  </div>
                )}
                {node.Publisher && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">Publisher</h2>
                    <p className="font-normal text-sm">{node.Publisher}</p>
                  </div>
                )}
                {node.TypeWork && (
                  <div>
                    <hr />
                    <h2 className="font-semibold text-sm mt-2">Type of Work</h2>
                    <p className="font-normal text-sm">{node.TypeWork}</p>
                  </div>
                )}
                {node.Download && (
                  <div>
                    <hr />
                    <a href={node.Download} className="downloadButton" download>Download</a>
                  </div>
                )}
                 {node.File && (
                  <div>
                    <hr />
                    <a href={node.File} className="downloadButton" download>Download</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Node;
