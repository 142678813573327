import {FETCH_PROJECTS} from '../constants/actionTypes';

const projectsReducer =(state={projects : null},action) =>{
    switch(action.type){
        case FETCH_PROJECTS:
            return action.payload;
        default:
            return state;
    }
}

export default projectsReducer;