const ServiceSection = () => {
  const serviceTitle =
    "By prioritising meaningful collaboration and research excellence, research from the CRCs continues to drive change in the sector as a long-term research base.";

  const serviceData = [
    "With more than 20,000 individual pieces of content, including case studies of research in action and a selection of resources (including briefing papers, journal articles and reports, researcher   blog posts, videos and posters), this site is an essential destination for natural hazards-related research outputs.",
    "Natural Hazards Research Australia, from 2021, became the new national research centre in natural hazards resilience and disaster risk reduction, built on the foundations of the two proceeding CRCs.",
  ];

  return (
    <div
      className=" relative"
      style={{ boxShadow: "0px 0px 12px #00000052",background:"#E6EBED", zIndex:1 }}
    >
      <div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  p-20 text-center lg:p-24 ">
          <div className="flex gap-36 justify-center">
            <h1 className="font-normal font text-4xl text-center max-w-[868px]" style={{font: "normal normal normal 32px/36px Titillium Web", letterSpacing: "0px", color: "#8C4799" , opacity: 1}}>
              {serviceTitle}
            </h1>
          </div>
          <div className="flex flex-col sm:flex-row gap-5 top-4  sm:gap-56 text-left mt-8 justify-center">
            {serviceData.map((item, index) => {
              return (
                <p style={{
                  font: "normal normal normal 18px/24px Titillium Web",letterSpacing: "0px",color: "#707070",opacity: "1"}}
                  key={index}
                  className=" max-w-md  text-base font-medium tracking-normal  text-blackish_grey 	"
                >
                  {item}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
