import {FETCH_PUBLICATIONS} from '../constants/actionTypes';

const publicationsReducer =(state={publications : null},action) =>{
    switch(action.type){
        case FETCH_PUBLICATIONS:
            return action.payload;
        default:
            return state;
    }
}

export default publicationsReducer;